import {
  GET_BANKS_FAIL,
  GET_BANKS_PENDING,
  GET_BANKS_SUCCESS,
} from "../actions";

const initialState = {
  isLoading: false,
  result: [],
  error: false,
  message: null,
  data: null,
};

export const banksReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_BANKS_PENDING:
      return {
        ...initialState,
        isLoading: true,
      };
    case GET_BANKS_SUCCESS:
      return {
        ...state,
        result: payload,
        isLoading: false,
        data: payload && payload.data,
      };
    case GET_BANKS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: payload && payload.message,
      };

    default:
      return state;
  }
};
