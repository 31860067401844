import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { setAuthToken, axios } from "./utils/axios";
import configureStore from "./store";
import Pusher from "pusher-js";



if (localStorage["access_token"]) {
  setAuthToken(localStorage["access_token"]);
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

const store = configureStore();

const pusherKey = process.env.REACT_APP_PUSHER_KEY;
export const pusher = new Pusher(pusherKey, {
  cluster: "ap2",
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.data?.message == "User is not logged in.") {
    localStorage.clear()
    window.location.reload();
  }

  return Promise.reject(error);
});

const app = (
  <Provider store={store}>
    {/* <BrowserRouter basename={config.basename}> */}
    <BrowserRouter basename={config.basename}>
      {/* basename="/datta-able" */}
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
