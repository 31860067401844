import roundToNearestMinutes from "date-fns/fp/roundToNearestMinutes";
import React from "react";
// import PrivacyPolicy from "./Demo/Homepage/page/PrivacyPolicy";

// const SignUp1 = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
// const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));
const Home = React.lazy(() =>
  import("./Demo/Homepage/page/Home")
);
const AboutUs = React.lazy(() =>
  import("./Demo/Homepage/page/AboutUs")
);
const Pricing = React.lazy(() =>
  import("./Demo/Homepage/page/Pricing")
);
const SignUp1 = React.lazy(() =>
  import("./Demo/Authentication/SignUp/SignUp1")
);
const Signin1 = React.lazy(() =>
  import("./Demo/Authentication/SignIn/SignIn1")
);
const ChangePassword = React.lazy(() =>
  import("./Demo/Authentication/Change-Password")
);
const ResetPassword = React.lazy(() =>
  import("./Demo/Authentication/Reset-Password")
);
const VerifyOTP = React.lazy(() => import("./Demo/Authentication/VerifyOTP"));

const ResendToken = React.lazy(() =>
  import("./Demo/Authentication/Resend-Token")
);

const PaymentLink = React.lazy(() => import("./Demo/Link/Link"));

const PrivacyPolicy = React.lazy(() => import("./Demo/Homepage/page/PrivacyPolicy"))

const Terms = React.lazy(() => import("./Demo/Homepage/page/Terms"))

const ElectronicCommunicationPolicy = React.lazy(() => import("./Demo/Homepage/page/ElectronicCommunicationPolicy"))
const AcceptableUsePolicy = React.lazy(() => import("./Demo/Homepage/page/AcceptableUsePolicy"))

const Download = React.lazy(() => import("./Demo/Homepage/page/Download"))
const ContactUs = React.lazy(() => import("./Demo/Homepage/page/ContactUs"))

// https://accelerate-web.netlify.app/payment_link?reference=pRD4FfqMjsz4oONEfoMA

const route = [
  // { path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },
  // { path: '/auth/signin-1', exact: true, name: 'Signin 1', component: Signin1 }
  { path: "/home", exact: true, name: "Home", component: Home },
  // { path: "/about-us", exact: true, name: "About Us", component: AboutUs },
  { path: "/pricing", exact: true, name: "Pricing", component: Pricing },
  { path: "/login", exact: true, name: "Login", component: Signin1 },
  { path: "/register", exact: true, name: "Register", component: SignUp1 },
  { path: "/privacy-policy", exact: true, name: "PrivacyPolicy", component: PrivacyPolicy },
  { path: "/terms", exact: true, name: "Terms", component: Terms },
  { path: "/electronic-communication-policy", exact: true, name: "ElectronicCommunicationPolicy", component: ElectronicCommunicationPolicy },
  { path: "/acceptable-use-policy", exact: true, name: "AcceptableUsePolicy", component: AcceptableUsePolicy },
  {
    path: "/payment_link",
    exact: false,
    name: "Payment Link",
    component: PaymentLink,
  },
  {
    path: "/reset-password",
    exact: true,
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/change-password",
    exact: true,
    name: "Change Password",
    component: ChangePassword,
  },
  {
    path: "/verify-otp",
    exact: true,
    name: "Verify OTP",
    component: VerifyOTP,
  },
  {
    path: "/resend-token",
    exact: true,
    name: "Resend Token",
    component: ResendToken,
  },
  {
    path: "/download",
    exact: true,
    name: "Download",
    component: Download,
  },
  {
    path: "/contact",
    exact: true,
    name: "Download",
    component: ContactUs,
  },
];

export default route;
