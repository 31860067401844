import {
  ACTIVATE_BRANCH_FAIL,
  ACTIVATE_BRANCH_PENDING,
  ACTIVATE_BRANCH_SUCCESS,
  CLEAR_ERROR_TOAST,
  CREATE_BRANCH_FAILURE,
  CREATE_BRANCH_PENDING,
  CREATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAILURE,
  UPDATE_BRANCH_PENDING,
  UPDATE_BRANCH_SUCCESS,
  GET_BRANCHES_FAIL,
  GET_BRANCHES_PENDING,
  GET_BRANCHES_SUCCESS,
} from "../actions";

const initialState = {
  isLoading: false,
  result: [],
  error: false,
  message: "",
  data: [],
  activateLoading: false,
};

export const branchReducer = (state = initialState, action) => {
  const { payload, type } = action;
  console.log('this is the branch reducer payload =>', payload)
  switch (type) {
    case CREATE_BRANCH_PENDING:
      return {
        ...initialState,
        isLoading: true,
      };
    case CREATE_BRANCH_SUCCESS:
      return {
        ...state,
        result: payload,
        error: false,
        message: payload,
        isLoading: false,
        data: payload && payload,
      };
    case CREATE_BRANCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: payload && payload.message,
      };

    case UPDATE_BRANCH_PENDING:
      return {
        ...initialState,
        isLoading: true,
      };
    case UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        result: payload,
        error: false,
        message: payload,
        isLoading: false,
        data: payload && payload,
      };
    case UPDATE_BRANCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: payload && payload.message,
      };

    case GET_BRANCHES_PENDING:
      return {
        ...initialState,
        isLoading: true,
      };
    case GET_BRANCHES_SUCCESS:
      return {
        ...state,
        result: payload,
        isLoading: false,
        data: payload && payload.data,
      };
    case GET_BRANCHES_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: payload && payload.message,
      };
    case CLEAR_ERROR_TOAST:
      return {
        ...state,
        isLoading: false,
        error: false,
        message: "",
      };
    case ACTIVATE_BRANCH_PENDING:
      return {
        ...state,
        activateLoading: true,
      };
    case ACTIVATE_BRANCH_SUCCESS:
      return {
        ...state,
        activateLoading: false,
      };
    case ACTIVATE_BRANCH_FAIL:
      return {
        ...state,
        activateLoading: false,
        error: true,
        message: payload && payload.message,
      };

    // case UPDATE_PASSWORD_PENDING:
    //   return {
    //     ...state,
    //     error: false,
    //     message: null,
    //   };
    // case UPDATE_PASSWORD_SUCCESS:
    //   return {
    //     ...state,
    //     message: payload,
    //     error: false,
    //   };
    // case UPDATE_PASSWORD_FAIL:
    //   return {
    //     ...state,
    //     error: true,
    //     message: payload.message,
    //   };

    default:
      return state;
  }
};
