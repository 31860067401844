// Layout

export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";

// AUthentication

/**
 *  - Register
 */
export const REGISTER_USER_PENDING = "REGISTER_USER_PENDING";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
export const CLEAR_REGISTER_USER_TOAST = "CLEAR_REGISTER_USER_TOAST";
export const SET_USER_TYPE = "SET_USER_TYPE"

/**
 *  - Login
 */
export const LOGIN_USER_PENDING = "LOGIN_USER_PENDING";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const CLEAR_LOGIN_USER_TOAST = "CLEAR_LOGIN_USER_TOAST";

/**
 *  - Settlements
 */
export const GET_SETTLEMENTS_PENDING = "GET_SETTLEMENTS_PENDING";
export const GET_SETTLEMENTS_SUCCESS = "GET_SETTLEMENTS_SUCCESS";
export const GET_SETTLEMENTS_FAIL = "GET_SETTLEMENTS_FAIL";

/**
 *  - Transactions
 */
export const GET_TRANSACTIONS_PENDING = "GET_TRANSACTIONS_PENDING";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";

/**
 *  - Payment Links
 */
export const GET_PAYMENT_LINKS_PENDING = "GET_PAYMENT_LINKS_PENDING";
export const GET_PAYMENT_LINKS_SUCCESS = "GET_PAYMENT_LINKS_SUCCESS";
export const GET_PAYMENT_LINKS_FAIL = "GET_PAYMENT_LINKS_FAIL";

/**
 *  - Initiate payment
 */
export const INITIATE_PAYMENT_PENDING = "INITIATE_PAYMENT_PENDING";
export const INITIATE_PAYMENT_SUCCESS = "INITIATE_PAYMENT_SUCCESS";
export const INITIATE_PAYMENT_FAIL = "INITIATE_PAYMENT_FAIL";
export const CLEAR_INITIATE_PAYMENT_TOAST = "CLEAR_INITIATE_PAYMENT_TOAST";

/**
 *  - Verify payment
 */
export const VERIFY_PAYMENT_PENDING = "VERIFY_PAYMENT_PENDING";
export const VERIFY_PAYMENT_SUCCESS = "VERIFY_PAYMENT_SUCCESS";
export const VERIFY_PAYMENT_FAIL = "VERIFY_PAYMENT_FAIL";
export const CLEAR_VERIFY_PAYMENT_TOAST = "CLEAR_VERIFY_PAYMENT_TOAST";

/**
 *  - Banks
 */
export const GET_BANKS_PENDING = "GET_BANKS_PENDING";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_BANKS_FAIL = "GET_BANKS_FAIL";

/**
 *  - Employees
 */

export const CREATE_EMPLOYEE_PENDING = "CREATE_EMPLOYEE_PENDING";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_FAILURE = "CREATE_EMPLOYEE_FAILURE";

export const GET_EMPLOYEES_PENDING = "GET_EMPLOYEES_PENDING";
export const GET_EMPLOYEES_FAIL = "GET_EMPLOYEES_FAIL";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const CLEAR_ERROR_TOAST = "CLEAR_ERROR_TOAST";

// activate and deactivate employees
export const ACTIVATE_EMPLOYEE_PENDING = "ACTIVATE_EMPLOYEE_PENDING";
export const ACTIVATE_EMPLOYEE_FAIL = "ACTIVATE_EMPLOYEE_FAIL";
export const ACTIVATE_EMPLOYEE_SUCCESS = "ACTIVATE_EMPLOYEE_SUCCESS";

/**
 *  - Branch
 */

export const CREATE_BRANCH_PENDING = "CREATE_BRANCH_PENDING";
export const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS";
export const CREATE_BRANCH_FAILURE = "CREATE_BRANCH_FAILURE";

export const UPDATE_BRANCH_PENDING = "UPDATE_BRANCH_PENDING";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAILURE = "UPDATE_BRANCH_FAILURE";

export const GET_BRANCHES_PENDING = "GET_BRANCHES_PENDING";
export const GET_BRANCHES_FAIL = "GET_BRANCHES_FAIL";
export const GET_BRANCHES_SUCCESS = "GET_BRANCHES_SUCCESS";
// export const CLEAR_ERROR_TOAST = "CLEAR_ERROR_TOAST";

// activate and deactivate BRANCHs
export const ACTIVATE_BRANCH_PENDING = "ACTIVATE_BRANCH_PENDING";
export const ACTIVATE_BRANCH_FAIL = "ACTIVATE_BRANCH_FAIL";
export const ACTIVATE_BRANCH_SUCCESS = "ACTIVATE_BRANCH_SUCCESS";

//Update password
export const UPDATE_PASSWORD_PENDING = "UPDATE_PASSWORD_PENDING";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
