import { combineReducers } from "redux";
import { registerReducer } from "./register";
import { layoutReducer } from "./layout";
import { loginReducer } from "./login";
import { settlementsReducer } from "./settlements";
import { transactionsReducer } from "./transactions";
import { paymentReducer } from "./payment";
import { banksReducer } from "./banks";
import { paymentLinksReducer } from "./paymentlinks";
import { employeeReducer } from "./employee";
import { branchReducer } from "./branch";

const rootReducer = combineReducers({
  layout: layoutReducer,
  register: registerReducer,
  login: loginReducer,
  settlements: settlementsReducer,
  transactions: transactionsReducer,
  payment: paymentReducer,
  banks: banksReducer,
  links: paymentLinksReducer,
  employee: employeeReducer,
  branch: branchReducer,
});

export default rootReducer;
