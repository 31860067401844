import React from "react";
import { Route, Redirect } from "react-router-dom";

function ProtectedRoute ({ component: Component, ...rest })  {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("isUserAuth") === 'true' ? (
          <Component {...props} {...rest} />
        ) : (
          // <Redirect to="/login" />
          <Redirect to="/home" />
        )
      }
    />
  );
  }

export { ProtectedRoute };