import {
  ACTIVATE_EMPLOYEE_FAIL,
  ACTIVATE_EMPLOYEE_PENDING,
  ACTIVATE_EMPLOYEE_SUCCESS,
  CLEAR_ERROR_TOAST,
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_PENDING,
  CREATE_EMPLOYEE_SUCCESS,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYEES_PENDING,
  GET_EMPLOYEES_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_PENDING,
  UPDATE_PASSWORD_SUCCESS,
} from "../actions";

const initialState = {
  isLoading: false,
  result: [],
  error: false,
  message: "",
  data: null,
  activateLoading: false,
};

export const employeeReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case CREATE_EMPLOYEE_PENDING:
      return {
        ...initialState,
        isLoading: true,
      };
    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        result: payload,
        error: false,
        message: payload,
        isLoading: false,
        data: payload && payload,
      };
    case CREATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: payload && payload.message,
      };
    case GET_EMPLOYEES_PENDING:
      return {
        ...initialState,
        isLoading: true,
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        result: payload,
        isLoading: false,
        data: payload && payload.data,
      };
    case GET_EMPLOYEES_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: payload && payload.message,
      };
    case CLEAR_ERROR_TOAST:
      return {
        ...state,
        isLoading: false,
        error: false,
        message: "",
      };
    case ACTIVATE_EMPLOYEE_PENDING:
      return {
        ...state,
        activateLoading: true,
      };
    case ACTIVATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        activateLoading: false,
      };
    case ACTIVATE_EMPLOYEE_FAIL:
      return {
        ...state,
        activateLoading: false,
        error: true,
        message: payload && payload.message,
      };

    case UPDATE_PASSWORD_PENDING:
      return {
        ...state,
        error: false,
        message: null,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        message: payload,
        error: false,
      };
    case UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        error: true,
        message: payload.message,
      };

    default:
      return state;
  }
};
