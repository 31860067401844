import {
  INITIATE_PAYMENT_FAIL,
  INITIATE_PAYMENT_PENDING,
  INITIATE_PAYMENT_SUCCESS,
  CLEAR_INITIATE_PAYMENT_TOAST,
  VERIFY_PAYMENT_FAIL,
  VERIFY_PAYMENT_PENDING,
  VERIFY_PAYMENT_SUCCESS,
  CLEAR_VERIFY_PAYMENT_TOAST,
} from "../actions";

const initialState = {
  isLoading: false,
  result: null,
  error: false,
  message: null,
  isVerified: false,
  isInitiated: false,
  verifiedResult: null,
  isVerifying: false,
};

export const paymentReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case INITIATE_PAYMENT_PENDING:
      return {
        ...initialState,
        isLoading: true,
      };
    case INITIATE_PAYMENT_SUCCESS:
      return {
        ...state,
        result: payload,
        isLoading: false,
        error: false,
        message: null,
        isInitiated: true,
      };
    case INITIATE_PAYMENT_FAIL:
      return {
        ...state,
        result: null,
        isLoading: false,
        error: true,
        message: payload && payload.message,
        isInitiated: false,
      };

    case CLEAR_INITIATE_PAYMENT_TOAST:
      return {
        ...state,
        error: false,
        isInitiated: false,
      };

    case VERIFY_PAYMENT_PENDING:
      return {
        ...state,
        isVerifying: true,
      };

    case VERIFY_PAYMENT_SUCCESS:
      return {
        ...state,
        verifiedResult: payload,
        isVerifying: false,
        error: false,
        message: null,
        isVerified: true,
      };
    case VERIFY_PAYMENT_FAIL:
      return {
        ...state,
        verifiedResult: null,
        isVerifying: false,
        error: true,
        message: payload && payload.message,
        isVerified: false,
      };

    case CLEAR_VERIFY_PAYMENT_TOAST:
      return {
        ...state,
        error: false,
        isVerified: false,
      };

    default:
      return state;
  }
};
