import {
  CLEAR_LOGIN_USER_TOAST,
  LOGIN_USER_FAIL,
  LOGIN_USER_PENDING,
  LOGIN_USER_SUCCESS,
  SET_USER_TYPE
} from "../actions";

const initialState = {
  isLoading: false,
  result: null,
  error: false,
  message: null,
  userType: null
};

export const loginReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case LOGIN_USER_PENDING:
      return {
        ...state,
        isLoading: true,
        result: null,
        error: false,
        message: null,
      };
    case LOGIN_USER_SUCCESS:
      localStorage["access_token"] = payload.token;
      localStorage["isUserAuth"] = true;
      localStorage["user_payload"] = JSON.stringify(payload.user);
      localStorage["profile_payload"] = JSON.stringify(payload.profile);
      localStorage["user_type"] = payload.profile.role;
      return {
        ...state,
        result: payload,
        isLoading: false,
        error: false,
        message: null,
      };
    case LOGIN_USER_FAIL:
      return {
        ...state,
        result: null,
        isLoading: false,
        error: true,
        message: payload && payload.message,
      };

    case CLEAR_LOGIN_USER_TOAST:
      return {
        ...state,
        isLoading: false,
        error: false,
        message: null,
        result: null,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload
      }

    default:
      return state;
  }
};
